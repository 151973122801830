import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Story from "../components/story"
import SEO from "../components/seo"

export const data = graphql`
  query {
    allStories: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        slug {
          current
        }
        publishedAt(formatString: "MM/DD/YYYY")
        category {
          title
          slug {
            current
          }
        }
        people
        designation
        excerpt
      }
    }
  }
`

export default function Stories({ data }) {
  const allStories = data.allStories
  // LML Article Of The Week, Left for Future Implementation
  // const lmlWinners = data.lmlWinners.nodes
  return (
    <Layout>
      <SEO title="Stories"/>
      <div className="mx-4 sm:mx-8 xl:mx-6">
        {/* Left for Future Implementation */}
        {/* {lmlWinners.length > 1 ? (
          <div className="mt-8">
            <h2>
              <span className="font-medium">Latest Winners</span> (Article Of
              The Week)
            </h2>
          </div>
        ) : (
          ""
        )} */}

        <h3 className="mt-8 section-head text-4xl xl:text-5xl text-red-700 ">
          Stories of Bloom
        </h3>
        <div className="mt-4">
          {allStories.nodes.map(story => (
            <Story
              title={story.title}
              slug={story.slug.current}
              mainImage={story.mainImage}
              excerpt={story.excerpt}
              date={story.publishedAt}
              category={story.category}
              people={story.people}
              designation={story.designation}
              key={story.slug.current}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}
